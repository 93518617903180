<script>
  import {mapGetters} from 'vuex';
  import PostAssignmentPrototype from '@/prototypes/PostAssignmentPrototype';

  export default {
    asyncData({store, route}) {
      let promisesArr = '';
      const copyId = route.query?.copy;

      if (copyId) {
        promisesArr = [...promisesArr, store.dispatch('PostTranslationProjectStore/getCopyProject', {copyProjectId: copyId})];
      } else {
        store.dispatch('PostingNotifierStore/preselectProjectStaticData');
      }
      store.dispatch('PostingNotifierStore/setIsTranslation', true);
      if (store.getters['PostingNotifierStore/mainDataShouldBeFetched']) {
        promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/fetchMainData')
          // after department is fetched check enterprise and dep't access levels
          .then(() => {
            if (!copyId) store.dispatch('PostingNotifierStore/preselectProjectDefaultAccessLevel');
          })];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    extends: PostAssignmentPrototype,
    computed: {
      ...mapGetters('PostInterpretationStore', ['bookingFormProgress']),
      orderTranslationType() { return this.$route.meta.assignmentType; },
      mainLines() {
        return [{
          components: [{
            name: 'multiple-language-selection',
            params: {
              showFromLang: true,
              useSelectLanguagePopup: true,
              showAssignmentHelper: true,
              assignmentType: this.orderTranslationType
            }
          }]
        }, {
          components: [{
            name: 'deadline-section',
            params: {
              isDateOptional: true,
              showSupportHint: true,
              miniDeadlineDays: 2,
              hideTime: true,
              showReset: true,
              tooltipText: this.$pgettext('translation', 'We will contact you as soon as possible, and will then confirm if we are able to deliver within your required deadline.'),
              showAssignmentHelper: true,
              context: 'translation',
              assignmentType: this.orderTranslationType
            }
          }]
        }, {
          components: [{
            name: 'subject-section',
            condition: this.showCategoriesSection,
            params: {
              isTranslationProject: true,
              showAssignmentHelper: true,
              showDropdown: true
            }
          }]
        }, {
          components: [{
            name: 'project-attachments-section',
            params: {
              showAssignmentHelper: true,
            },
            condition: this.orderTranslationType != 'in_person'
          }]
        }, {
          components: [{
            name: 'project-extra-info-section',
            params: {
              extraInfoText: this.$pgettext('translation', 'Is there anything we should know about your document?'),
              showAssignmentHelper: true
            }
          }]
        }];
      },
      additionalLines() {
        return [
          {
            components: [{
              name: 'participants-section',
              params: {
                hidePhoneParticipants: true,
                showAssignmentHelper: true,
                context: 'translation'
              }
            }]
          },
          {
            components: [{
              name: 'access-level-section',
              params: {
                showAssignmentHelper: true
              }
            }],
          }];
      },
      footerTextParams() {
        return {
          dateTimes: [{
            startTime: this.deadline
          }],
          languageId: this.languageFrom
        };
      },
      submitBtnText() {
        return this.$pgettext('translation', 'Send in');
      },
      summaryObj() {
        return {
          title: this.$pgettext('translation', 'Translation Project Information'),
          showFileUploadCheckbox: true,
          job: {
            languageFromId: this.languageFrom,
            targetLanguageIds: this.targetLanguages,
            deadline: this.deadline,
            department: {
              departmentId: this.department,
              departmentsList: this.departmentsList
            },
            booker: this.booker,
            ownerRealName: this.ownerRealName,
            bookingRef: this.bookingRef,
            paymentBookingRef: this.paymentBookingRef,
            caseNumber: this.caseNumber,
            description: this.extraInfo,
            attachments: this.attachments,
            paymentMethod: {
              paymentMethodId: this.showPaymentMethod ? this.paymentMethod : undefined,
              paymentMethodsList: this.paymentMethods
            },
            name: this.name,
            company: this.company,
            address: this.address
          }
        };
      },
    },
    watch: {
      orderTranslationType: {
        handler(newOrderTranslationType) {
          this.$store.commit('PostTranslationProjectStore/setSubtaskType', newOrderTranslationType);
        },
        immediate: true
      }
    },
    methods: {
      sendInfoFunc() {
        return this.$store.dispatch('PostTranslationProjectStore/createProject');
      },
      validatePage() {
        this.$store.commit('ErrorsStore/removeAllErrors');
        const personalInfoIsValid = this.validateBookingReferenceSection()
          & this.validateDepartmentAttendersSection()
          & this.validateCaseNumberSection()
          & this.validateOwnerRealNameSection()
          & this.validateSendMailSection()
          & this.validateCoverLettersSection()
          & this.validateNonLatinLang();
        const assignmentInfoIsValid = this.validateSourceTargetLanguagesSection()
          // Validate files number and size of file
          & this.validateAttachedFiles({maxSize: 50, maxFiles: 100, fileIsRequired: this.userHasRequireOrderAttachment})
          & this.validateDeadlineSection()
          & this.validateNonLatinLang();
        return personalInfoIsValid && assignmentInfoIsValid;
      },
      handleSuccess() {
        this.$router.push(this.$makeRoute(
          {name: 'BuyerOneProject',
           params: {
             id: this.$store.state.OneProjectStore.project.id,
           }}
        ));
      },
      handleError(error) {
        if (error?.data?.errors['global!']) {
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'PaymentMethodStore',
            fieldName: 'paymentMethod',
            errorText: error?.data?.errors['global!'][0],
          });
          this.$emit('postassignfailed');
        } else if (error?.status == 401) {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
            this.$emit('postassignfailed');
            this.$store.dispatch('ModalStore/closeModal');
          });
        } else if (error
          && error.data
          && error.data.errors
          && ((error.data.errors.participants
            && error.data.errors.participants['0']
            && error.data.errors.participants['0'].uid
            && error.data.errors.participants['0'].uid.length
            && error.data.errors.participants['0'].uid.indexOf('Wrong participant') !== -1)
            || (error.data.errors.requirement
              && error.data.errors.requirement.department_id
              && error.data.errors.requirement.department_id.length
              && error.data.errors.requirement.department_id.indexOf('There is no relation with this id') !== -1))) {
          this.fetchDepartments();
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'DepartmentAttendersStore',
            fieldName: 'department',
            errorText: ''
          });
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'DepartmentAttendersStore',
            fieldName: 'attenders',
            errorText: ''
          });
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('It seems like something has been changed in your Enterprise. Please try again.')
            });
          }, 0);
        } else {
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      }
    },
    mounted() {
      if (!this.$cookieManager.getCookie('lastUsedType') || this.$cookieManager.getCookie('lastUsedType') !== 'translation') {
        this.$cookieManager.setCookie('lastUsedType', 'translation');
      }
    },
    beforeRouteLeave(to, from, next) {
      // only if user leaves posting pages
      const assignmentType = to.meta.assignmentType || '';
      if (!assignmentType) {
        this.$store.dispatch('PostTranslationProjectStore/clearPersonalInfoAndInvoiceStores');
      }

      const assignmentHelperEnabled = this.$cookieManager.getCookie('assignmentHelperEnabled');
      if (assignmentHelperEnabled) {
        this.openEmptyAssignmentHelper(JSON.parse(assignmentHelperEnabled));
      }

      this.$store.dispatch('PostTranslationProjectStore/clearAssignmentInfoStores');
      this.$store.commit('PostingNotifierStore/clearStore');
      this.$store.commit('PostTranslationProjectStore/clearStore');
      next();
    }
  };
</script>
